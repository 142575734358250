<template>
  <div>
    <template v-if="loading">
      <div class="page-segment margin-top">
        <div class="page-segment-content">
          <div class="margin-bottom-sm" v-for="index in 3" :key="index">
            <Skeleton width="100%" height="122px" borderRadius="16px" />
          </div>
        </div>
      </div>
    </template>

    <template v-if="!loading && specialists.length > 0">
      <div class="page-segment margin-top">
        <div class="page-segment-content">
          <div v-for="(specialist, key) in specialists" :key="key">
            <PersonCard
              v-selectable-container="{
                callback: () =>
                  navigateSpecialistProfile(specialist.specialist_id)
              }"
              :image="specialistMapped(specialist).image"
              :title="specialistMapped(specialist).title"
              :firstLine="specialistMapped(specialist).firstLine"
              :middleLine="specialistMapped(specialist).middleLine"
              :bottomLine="specialistMapped(specialist).bottomLine"
              :showAction="specialistMapped(specialist).showAction"
              class="margin-bottom-xs"
            />
          </div>
        </div>
        <LoaderSimple
          class="margin-y"
          v-if="infinityScroll && specialists.length >= 10"
        />
      </div>
    </template>

    <template v-if="!loading && specialists.length === 0">
      <DataZeroSimple
        :text="$translations['my-specialists']['no-specialists']"
        :image="image"
        :buttons="buttons"
      />
    </template>
  </div>
</template>

<script>
import {
  SpecialistFavorite,
  Skeleton,
  LoaderSimple,
  PersonCard,
  selectableContainer
} from '@seliaco/red-panda'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import SpecialistsListMixin from '@/mixins/specialists-list-mixin'

export default {
  name: 'HistoryTab',
  mixins: [SpecialistsListMixin],
  directives: { selectableContainer },
  components: { DataZeroSimple, PersonCard, Skeleton, LoaderSimple },
  data () {
    return {
      specialists: [],
      loading: true,
      container: 'tab-content',
      buttons: [
        {
          text: this.$translations['my-specialists']['find-specialist'],
          type: 'block',
          redirect: { name: 'Search' },
          segment_data: {
            origin: 'My Specialists - History',
            option: 'FIND-SPECIALIST'
          }
        }
      ]
    }
  },
  mounted () {
    this.query = this.$route.query.request_query
      ? JSON.parse(this.$route.query.request_query)
      : { page: 1, per_page: 10 }

    this.getSpecialists(this.query)
    this.setListener()
  },
  methods: {
    getSpecialists (query) {
      if (this.page === 1) {
        this.loading = true
      }

      this.query = query || { page: this.page, per_page: 10 }

      if (this.preventMultipleCalls) {
        SpecialistFavorite.getHistory(this.query)
          .then((response) => {
            this.totalOfPages = response.headers.pages
            this.totalCount = response.headers.count

            if (this.page === 1) {
              this.specialists = response.data
            } else {
              this.specialists = [...this.specialists, ...response.data]
            }
          })
          .catch(() => {
            this.$toast({
              text: this.$translations.error['specialist-list'],
              severity: 'error'
            })
          })
          .finally(() => {
            this.loading = false
            this.infinityScroll = false
            this.preventMultipleCalls = false
          })
      }
    },
    navigateSpecialistProfile (specialistId) {
      this.$router.push({
        name: 'SpecialistDetail',
        params: {
          id: specialistId
        },
        query: {
          back: this.$route.fullPath
        }
      })
    }
  },
  computed: {
    image () {
      return require('@/assets/icons/no_specialists.svg')
    },
    specialistMapped () {
      return (option) => {
        const timeZone = this.$store.getters.userTimezone
        return {
          image: option?.profile_picture,
          title: `${option?.first_name} ${option?.last_name}`,
          firstLine: option?.speciality,
          middleLine: this.$translations['my-specialists'][
            'last-appointment'
          ].replaceAll(
            '{date}',
            this.$moment(option?.next_appointment).tz(timeZone).format('ll')
          ),
          bottomLine: this.$translations['my-specialists'][
            'appointments-completed'
          ].replaceAll('{number}', option?.appointments_completed),
          showAction: true
        }
      }
    }
  }
}
</script>
