<template>
  <div>
    <template v-if="loading">
      <div v-for="index in 2" :key="index" class="page-segment margin-top">
        <div class="page-segment-content">
          <div class="margin-bottom-sm">
            <Skeleton width="100%" height="55px" borderRadius="16px" />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <template v-if="!loading && recommendations">
        <template v-for="(recommendation, key) in recommendations">
          <div class="page-segment margin-top" :key="key" v-if="recommendation.response && recommendation.response.length">
            <div class="page-segment-content">
              <div class="section-title">
                <div class="section-title-text content-title text-gray-80">
                  <span v-text="recommendation.title"/>
                  <div v-tooltip="recommendation.tooltip" class="icon-information-off tooltip-icon" />
                </div>
                <span
                  @click="toggleSection(key)"
                  v-text="recommendation.show ? $translations['my-specialists'].hide : $translations['my-specialists'].show"
                  class="section-title-button content-title text-purple cursor-pointer"
                />
              </div>
              <template v-if="recommendation.show">
                <div v-for="(specialist, key) in recommendation.response" :key="key" class="margin-top-lg">
                  <SpecialistCard :specialist="specialist" />
                </div>
              </template>
            </div>
          </div>
        </template>
      </template>

      <div v-if="showCallToAction" class="page-segment">
        <div class="page-segment-content">
          <div class="white-section">
            <div
              class="body"
              v-text="$translations['suggested-specialists']['see-questionnaire']"
            />
            <Button
              type="link"
              :text="$translations['my-specialists']['recommended-sections'].action"
              @clicked="navigateMatchingView"
            />
          </div>
        </div>
      </div>

      <template v-else>
        <DataZeroSimple
          :text="$translations['my-specialists']['no-recommended']"
          :image="image"
          :buttons="buttons"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { SpecialistRecommended, Skeleton, tooltip, Button } from '@seliaco/red-panda'
import { OnboardingEvent, RecommendedEvent } from '@/types/events';
import SpecialistCard from '@/components/cards/specialist-card/SpecialistCardWrapper.vue'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'

export default {
  name: 'RecommendedTab',
  components: { DataZeroSimple, SpecialistCard, Skeleton, Button },
  directives: { tooltip },
  data () {
    return {
      recommendations: null,
      show: {
        orientation: false,
        matching: false
      },
      loading: true,
      buttons: [
        {
          text: this.$translations['my-specialists']['find-specialist'],
          type: 'block',
          redirect: { name: 'QuestionnaireContainer' },
          segment_data: {
            origin: 'My Specialists - Recommended',
            option: 'FIND-SPECIALISTS'
          }
        }
      ]
    }
  },
  mounted () {
    this.getSpecialists()
  },
  methods: {
    getSpecialists () {
      this.loading = true

      SpecialistRecommended.getRecommended('SPECIALIST')
        .then(data => {
          const recommendations = data

          if (this.$route.query.view) {
            Object.keys(recommendations).forEach(
              (key) => {
                recommendations[key].show = key === this.$route.query.view
              })
          }

          this.recommendations = recommendations


          RecommendedEvent.RecommendedViewed({
            userId: this.$store.getters["auth/user"].id,
            back: this.$route.query.back,
            matchingRecommended: this.recommendations.matching.response,
            matchingRecommendedCount: this.recommendations.matching.response.length,
            orientationRecommended: this.recommendations.orientation.response,
            orientationRecommendedCount: this.recommendations.orientation.response.length,
          })
        })
        .catch(e => {
          this.$toast({
            severity: 'error',
            text: e.message
          })
        }).finally(() => {
          this.loading = false
        })
    },
    toggleSection (option) {
      this.recommendations[option].show = !this.recommendations[option].show

      const newQuery = {
        ...this.$route.query,
        disableScroll: true
      }

      if (this.recommendations[option].show) {
        newQuery.view = option
      } else {
        delete newQuery.view
      }

      this.$router.replace({
        query: newQuery
      })
    },
    navigateMatchingView () {
      OnboardingEvent.Matching_Choose_View_Viewed({ origin: 'My recommendations' })
      this.$router.push({ name: 'QuestionnaireContainer' })
    }
  },
  computed: {
    image () {
      return require('@/assets/icons/no_recommended.svg')
    },
    showCallToAction () {
      if (!this.recommendations) {
        return false
      }

      return Object.values(this.recommendations).some(
        recommendation => recommendation.response?.length
      )
    }
  },
  watch: {
    '$route.query': {
      handler (query) {
        if (query?.view && !this.show[query.view]) {
          this.show[query.view] = true
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
.section-title
  display: flex
  justify-content: space-between
  align-items: center
  &-text
    display: flex
    align-items: center
    gap: 8px
    .tooltip-icon
      color: var(--gray-40)
      font-size: var(--xl)
.white-section
  padding: 16px 16px 0
  text-align: center
</style>
