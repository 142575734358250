// DEPRECATED
import { Specialists } from '@seliaco/red-panda'
import { SpecialistSearch } from '@/types/events'
export default {
  data () {
    return {
      controller: null,
      infinityScroll: false,
      totalOfPages: 1,
      totalCount: null,
      page: 1,
      getSpecialist: true,
      query: {},
      preventMultipleCalls: true
    }
  },
  destroyed () {
    this.removeListeners()
  },
  methods: {
    getSpecialists (query) {
      if (this.page === 1) {
        this.loading = true
      }

      this.query = query || { page: this.page, per_page: 10, seed: this.seed }

      if (this.specialitiesId) {
        this.query = {
          ...this.query,
          speciality: this.specialitiesId
        }
      }

      if (this.preventMultipleCalls) {
        Specialists.list(this.query)
          .then((response) => {
            this.totalOfPages = response.headers.pages
            this.totalCount = response.headers['x-all-specialists-info']

            if (this.page === 1) {
              this.specialists = response.data
            } else {
              this.specialists = [...this.specialists, ...response.data]
            }

            if (this.origin === 'SpecialistSearch') {
              this.segmentEvents()
            }
          })
          .catch(() => {
            this.$toast({
              text: this.$translations.error['specialist-list'],
              severity: 'error'
            })
          })
          .finally(() => {
            this.loading = false
            this.infinityScroll = false
            this.preventMultipleCalls = false
          })
      }
    },
    generateSeed () {
      return (Math.random() * (1 - -1) + -1).toFixed(10)
    },
    onScroll (event, isDocument = false) {
      if (this.page < this.totalOfPages && event) {
        const scrollTop = isDocument
          ? window.scrollY : event.target.scrollTop

        const scrollHeight = isDocument ? window.scrollMaxY ||
          Math.round(document.documentElement.scrollHeight - document.documentElement.clientHeight)
          : event.target.scrollHeight - event.target.clientHeight

        if ((scrollTop + (window.innerHeight / 2)) > scrollHeight && !this.preventMultipleCalls) {
          this.infinityScroll = true
          this.page++
          this.preventMultipleCalls = true
          if (this.$route.query.request_query) {
            const queryParam = JSON.parse(this.$route.query.request_query)
            const query = { ...queryParam, page: this.page, seed: this.seed }
            return this.getSpecialists(query)
          }
          this.getSpecialists()
        } else {
          this.infinityScroll = false
        }
      }
    },
    async setListener () {
      this.controller = new AbortController()
      const container = document.getElementsByClassName(
        this.container
      )[0]
      if (container) {
        container.addEventListener('scroll', this.onScroll, { signal: this.controller.signal })
      }
      window.addEventListener('scroll', ($event) => this.onScroll($event, true), { signal: this.controller.signal })
    },
    removeListeners () {
      this.controller.abort()
    },
    segmentEvents () {
      if (this.text.length > 0) {
        const body = {
          numSpecialistsFound: this.totalCount,
          searchTerm: this.text
        }
        SpecialistSearch.Specialist_Search_Successful(body)
      }

      if (this.filters) {
        const body = {
          numSpecialistsFound: this.totalCount,
          filters: this.$store.getters['filters/get']
        }
        SpecialistSearch.Specialist_Filter_Successful(body)
      }
    }
  }
}
