<template>
  <div>
    <template v-if="loading">
      <div class="page-segment margin-top">
        <div class="page-segment-content">
          <div class="margin-bottom-sm" v-for="index in 3" :key="index">
            <Skeleton width="100%" height="276px" borderRadius="16px" />
          </div>
        </div>
      </div>
    </template>

    <template v-if="!loading && specialists.length > 0">
      <div class="page-segment margin-top">
        <div class="page-segment-content">
          <div v-for="(specialist, key) in specialists" :key="key">
            <SpecialistCard :specialist="specialist" />
          </div>
        </div>
        <LoaderSimple
          class="margin-y"
          v-if="infinityScroll && specialists.length >= 10"
        />
      </div>
    </template>

    <template v-if="!loading && specialists.length === 0">
      <DataZeroSimple
        :text="$translations['my-specialists']['no-favorite']"
        :image="image"
        :buttons="buttons"
      />
    </template>
  </div>
</template>

<script>
import { SpecialistFavorite, Skeleton, LoaderSimple } from '@seliaco/red-panda'
import SpecialistCard from '@/components/cards/specialist-card/SpecialistCardWrapper.vue'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import SpecialistsListMixin from '@/mixins/specialists-list-mixin'

export default {
  name: 'FavoritesTab',
  mixins: [SpecialistsListMixin],
  components: { DataZeroSimple, SpecialistCard, Skeleton, LoaderSimple },
  computed: {
    image () {
      return require('@/assets/icons/no_favorite.svg')
    }
  },
  data () {
    return {
      specialists: [],
      loading: true,
      container: 'tab-content',
      buttons: [
        {
          text: this.$translations['my-specialists']['find-specialist'],
          type: 'block',
          redirect: { name: 'Search' },
          segment_data: {
            origin: 'My Specialists - Favorites',
            option: 'FIND-SPECIALIST'
          }
        }
      ]
    }
  },
  mounted () {
    this.query = this.$route.query.request_query
      ? JSON.parse(this.$route.query.request_query)
      : { page: 1, per_page: 10 }

    this.getSpecialists(this.query)
    this.setListener()
  },
  methods: {
    getSpecialists (query) {
      if (this.page === 1) {
        this.loading = true
      }

      this.query = query || { page: this.page, per_page: 10 }

      if (this.preventMultipleCalls) {
        SpecialistFavorite.getFavorites(this.query)
          .then((response) => {
            this.totalOfPages = response.headers.pages
            this.totalCount = response.headers.count

            if (this.page === 1) {
              this.specialists = response.data
            } else {
              this.specialists = [...this.specialists, ...response.data]
            }
          })
          .catch((error) => {
            this.$toast({
              text: error.message || this.$translations.error['specialist-list'],
              severity: 'error'
            })
          })
          .finally(() => {
            this.loading = false
            this.infinityScroll = false
            this.preventMultipleCalls = false
          })
      }
    }
  }
}
</script>
