<template>
  <TabContainer
    :title="$translations['my-specialists'].title"
    :actions="actions"
    :has-shy-header="true"
    :options="options"
    :route="$route"
  >
    <template v-slot:content>
      <HistoryTab v-if="$route.query.tab === 'history'" />
      <FavoritesTab v-if="$route.query.tab === 'favorites'" />
      <RecommendedTab v-if="$route.query.tab === 'recommended'" />
    </template>
  </TabContainer>
</template>

<script>
import { TabContainer } from '@seliaco/red-panda'
import HistoryTab from './tabs/HistoryTab'
import FavoritesTab from './tabs/FavoritesTab'
import RecommendedTab from './tabs/RecommendedTab'
export default {
  name: 'MySpecialists',
  components: {
    TabContainer,
    HistoryTab,
    FavoritesTab,
    RecommendedTab
  },
  data () {
    const options = [
      {
        text: this.$translations['my-specialists'].history,
        name: 'history',
        action: () => {
          this.$router.replace({
            query: {
              ...this.$route.query,
              tab: 'history'
            }
          })
        }
      },
      {
        text: this.$translations['my-specialists'].favorites,
        name: 'favorites',
        action: () => {
          this.$router.replace({
            query: {
              ...this.$route.query,
              tab: 'favorites'
            }
          })
        }
      },
      {
        text: this.$translations['my-specialists'].recommended,
        name: 'recommended',
        action: () => {
          this.$router.replace({
            query: {
              ...this.$route.query,
              tab: 'recommended'
            }
          })
        }
      },
    ]

    const actions = {
      back: {
        callback: () => {
          this.$router.replace({ name: this.$route.query.back })
        }
      }
    }

    return {
      actions,
      options
    }
  },
  mounted () {
    if (!this.$route.query.tab) {
      this.$router.replace({
        name: 'MySpecialists',
        query: {
          ...this.$route.query,
          tab: 'history'
        }
      })
    }
  }
}
</script>
